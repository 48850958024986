import * as React from 'react';
import Seo from '../components/layout/Seo'
import Layout from '../components/layout/Layout';
import Section from '../components/layout/Section'
// import VideoSliderLegacy from '../components/VideoSliderLegacy';
// import ThumbnailSlider from '../components/ThumbnailSlider'
// import { useMediaQuery } from 'react-responsive'
import loadable from '@loadable/component'
import {Spinner} from '../svg/LoadingIcon';
import useTimeout from '../utils/useTimeout'
import useSessionStorage from '../utils/useSessionStorage'
import { epVideoPremier as firstVideo } from '../data/releaseDate-data';
import PageHeader from '../components/PageHeader';

const ThumbnailSlider = loadable(() => import('../components/ThumbnailSlider'))

const VideoPage = () => {

  // const aboveMd = useMediaQuery({ query: '(min-width: 768px)' })

  const [hasLoaded, setHasLoaded] = React.useState(false)

  const [seen, setSeen] = useSessionStorage('hohn-media', false)
  const [visible, isVisible] = React.useState(seen ? 1.0 : 0)

  // React.useEffect(() => {

  //   setHasLoaded(true)

  //   return () => {
  //     setHasLoaded(false)
  //   }

  // }, [])

  const timedLoaded = () => {
    setHasLoaded(true)
    setSeen(true)
  }

  const setVisibility = () => {
    isVisible(1.0)
  }

  useTimeout(timedLoaded, 1500)
  useTimeout(setVisibility, 900)

  React.useEffect(() => {

    if (!hasLoaded && !seen) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'unset'
    }

    return () => {
      document.documentElement.style.overflow = 'unset'
    }

  }, [hasLoaded])

  const now = new Date()

  return (
    <Layout>
      <Seo title="Videos" />

	  <Section sectionName="Videos" bgColor="transparent">

        {firstVideo > now ? (
         <PageHeader 
            pageTitle={`Videos`}
            pageSubtitle={`Coming soon...`}
            size={`text-4xl md:text-5xl -mb-3`}
        />
        ) : (
          <div className="mx-auto px-1 relative" suppressHydrationWarning={true}>
          {!hasLoaded  ? (

            seen ? ( null ) 
            : (
              <>
            <div className={`block w-full mx-auto min-h-[98vh]`} />
            <div className={`shadow-lg z-[50] absolute top-0 left-0 right-0 mx-auto w-full flex flex-col items-center justify-center p-10 bg-[#b4aca3] dark:bg-[#2d374c] min-h-[98vh] rounded-md text-black`}><Spinner className={`opacity-40`} /></div>
            </>  
            )
            
          ) : (null) }
          <ThumbnailSlider className={visible ? 'opacity-100' : 'opacity-0'} />
        </div>
        )}


	  </Section>

    </Layout>
  )
}

export default VideoPage;
