

export const egyptLakeReleaseDate = new Date('2023-01-27T00:00:00');

// export const CCHReleaseDate = new Date('2023-02-24T00:00:00');



export const redEarthCreekReleaseDate = new Date('2023-02-24T00:00:00');
export const reckVideoDate = new Date('2023-03-03T00:00:00');
// export const reckVideoDate = new Date('2023-02-23T17:37:00');

export const hohnAlbumDate = new Date('2023-03-31T00:00:00');

// export const egyptLakeReleaseDate = new Date('2023-01-01T00:00:00');

// export const redEarthCreekReleaseDate = new Date('2023-01-10T00:00:00');

// export const hohnAlbumDate = new Date('2023-01-22T00:00:00');

export const epVideoPremier = new Date('2023-01-28T12:00:00');
// export const epVideoPremier = new Date('2023-01-26T15:18:00');